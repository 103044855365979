.bigVideo{
    width: 100%;
    height: 100%;
    position: relative; 
    padding-top: 56.25%;
}

.bigVideo iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
    
  }

  @media only screen and (max-width: 390px) {
    .bigVideo iframe{
        padding-top: 42%;
  }
  .bigVideo {
    margin-top: -40%;
}
}

@media only screen and (max-width: 530px) {
    .bigVideo iframe{
        padding-top: 15%;
  }
  .bigVideo {
    margin-top: -20%;
}
}

@media only screen and (max-width: 434px) {
    .bigVideo iframe{
        padding-top: 30%;
  }
  .bigVideo {
    margin-top: -25%;
}
}

/* @media only screen and (min-width: 700px) {
    .bigVideo iframe{
        padding-top: 56.25%;
  }
}

  @media only screen and (min-width: 1000px) {
    .bigVideo iframe{
        padding-top: 46.15%;
  }
  
  } */

 