@font-face {
  font-family: "Mistral";
  src: local("Mistral"), url(./fonts/Mistral/MISTRAL.TTF) format("truetype");
}

@font-face {
  font-family: "Gilligan";
  src: local("Gilligan"), url(./fonts/GILLIGAN.TTF) format("truetype");
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.mistral-font {
  font-family: Mistral, serif;
  color: #008000 !important;
}

.gilligan-font {
  font-family: Gilligan, serif;
  color: #008000 !important;
}

.widescreen {
  aspect-ratio: 16/9;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
